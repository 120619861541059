import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Hero from "./Hero";
import Footer from "./Footer";
import HowItWorks from "./Howitworks";
import AboutUs from "./Aboutus";
const Homepage = () => {
  useEffect(() => {
    ReactGA.initialize("G-LD75EJGDSN");
    ReactGA.send("pageview");
  }, []);

  const handleClaimNowClick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://bit.ly/ggnowww", "_blank");
  };
  return (
    <div className="relative">
      <Hero handleClaimNowClick={handleClaimNowClick} />
      <HowItWorks handleClaimNowClick={handleClaimNowClick} />
      <AboutUs handleClaimNowClick={handleClaimNowClick} />
      <Footer />
    </div>
  );
};

export default Homepage;
