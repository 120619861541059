import React from "react";

const Footer = () => {
  return (
    <footer className="w-full bg-gray-900 text-white py-6 px-4 text-center">
      <div className="max-w-7xl mx-auto">
        <p className="text-sm md:text-base font-bold text-green-700">
          **18+ Disclaimer: This website and its content are exclusively intended for individuals aged 18 and above. PhantomPlay is a free-to-play social gaming platform designed purely for entertainment purposes. There is no opportunity to win real money, and no actual currency can be spent or earned through any of the games available on this platform. Please note that success or skills gained while playing on PhantomPlay do not translate into or guarantee success in real-money games..**
        </p>
        <p className="text-sm md:text-base mt-2 text-gray-300">
          Note: No real money is involved in this platform. It is solely for entertainment purposes.
        </p>
        <hr className="my-4 border-gray-600" />
        <p className="text-xs md:text-sm text-gray-400">
          © {new Date().getFullYear()} . All rights reserved. Unauthorized use or duplication is prohibited.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
