import React from "react";

const AboutUs = ({ handleClaimNowClick }) => {
  return (
    <div className="w-full min-h-screen bg-gray-100 text-white flex flex-col gap-10 md:p-20  md:flex-row items-center justify-center  py-20">
      {/* Left Column: Image */}
      <div className=" w-full    ">
        <img
          onClick={handleClaimNowClick}
          src="https://cdn.shopify.com/s/files/1/0278/4565/6649/files/WhatsApp_Image_2023-09-17_at_21.50.17.webp?v=1694969476"
          alt="PhantomPlay"
          className="rounded-lg shadow-lg  object-cover  "
        />
      </div>

      {/* Right Column: About Us Content */}
      <div className="w-full  text-center md:text-left">
        <h1 className="text-4xl md:text-6xl font-bold text-orange-500 mb-5">
          About us
        </h1>
        <p className="text-lg md:text-xl text-gray-900 leading-relaxed mb-5">
         
          <span className="text-orange-400 font-bold">It</span>, is a trusted ID promoter, specializing in a wide range of sports, including online cricket IDs and various gaming sports IDs. We provide players with the opportunity to play games using our sports IDs. With over 90k players served, we are dedicated to helping gamers discover the best online gaming experiences.
        </p>
        <p className="text-lg md:text-xl text-gray-900 leading-relaxed">
          <span className="text-orange-400 font-bold">
            No real money involvement
          </span>
          . Play for free and have fun without any risk. Our platform is built
          for gamers who value entertainment, strategy, and social connections.
        </p>
        <button
          onClick={handleClaimNowClick}
          className="mt-10 py-3 px-8 bg-gradient-to-r from-orange-500 via-white to-green-500 hover:from-green-500 hover:via-white hover:to-orange-500 text-black  text-lg rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
        >
          Get Your ID Now
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
