import React, { useEffect } from "react";
import { FaGamepad, FaBookOpen, FaGift, FaWhatsapp } from "react-icons/fa";
import AOS from "aos";
import { RiSecurePaymentFill } from "react-icons/ri";
import { FcCustomerSupport } from "react-icons/fc";
import "aos/dist/aos.css";

const HowItWorks = ({ handleClaimNowClick }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: "false",
    });
  }, []);
  return (
    <div className="w-full h-auto bg-gray-200  py-16 px-5 md:px-20">
      <h1 className="text-center text-4xl md:text-5xl text-black font-bold mb-10">
        Features & Benifits
      </h1>
      <div
        data-aos="fade-down"
        className="grid grid-cols-1 md:grid-cols-4 gap-10 text-center"
      >
        <div
          onClick={handleClaimNowClick}
          className="flex flex-col items-center p-5 bg-transprent border-2 border-black rounded-lg shadow-lg"
        >
          <FaGamepad className="text-6xl text-black mb-5" />
          <h2 className="text-xl font-bold mb-2">Best Sports ID Provider</h2>
          <p className="text-sm text-gray-900">
            We offer the best fantasy sports IDs to players who want to showcase
            their skills and compete in their favorite games. ⚽
          </p>
          <button
            onClick={handleClaimNowClick}
            className="mt-10 py-3 px-8 bg-gradient-to-r from-orange-500 via-white to-green-500 hover:from-green-500 hover:via-white hover:to-orange-500 text-black  text-lg rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
          >
            Get Your ID Now
          </button>
        </div>
        <div
          onClick={handleClaimNowClick}
            className="flex flex-col items-center p-5 bg-transprent border-2 border-black rounded-lg shadow-lg"
        >
          <FaBookOpen className="text-6xl text-yellow-500 mb-5" />
          <h2 className="text-xl font-bold mb-2">WORKING SINCE 2016</h2>
          <p className="text-sm text-black">
            We have been working in this field since 2013, which has given us
            extensive experience and a deep understanding, setting us apart from
            newcomers.
          </p>
          <button
            onClick={handleClaimNowClick}
            className="mt-10 py-3 px-8 bg-gradient-to-r from-orange-500 via-white to-green-500 hover:from-green-500 hover:via-white hover:to-orange-500 text-black  text-lg rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
          >
            Get Your ID Now
          </button>
        </div>
        <div
          onClick={handleClaimNowClick}
   className="flex flex-col items-center p-5 bg-transprent border-2 border-black rounded-lg shadow-lg"
        >
          <RiSecurePaymentFill className="text-6xl text-green-500 mb-5" />
          <h2 className="text-xl font-bold mb-2">HIGH SECURITY</h2>
          <p className="text-sm text-black">
            Security is our top priority. Your data and information are always
            safe with us and are never shared at any point. ⚽
          </p>
          <button
            onClick={handleClaimNowClick}
            className="mt-10 py-3 px-8 bg-gradient-to-r from-orange-500 via-white to-green-500 hover:from-green-500 hover:via-white hover:to-orange-500 text-black  text-lg rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
          >
            Get Your ID Now
          </button>
        </div>
        <div
          onClick={handleClaimNowClick}
            className="flex flex-col items-center p-5 bg-transprent border-2 border-black rounded-lg shadow-lg"
        >
          <FcCustomerSupport className="text-6xl text-yellow-500 mb-5" />
          <h2 className="text-xl font-bold mb-2">24/7 SUPPORT</h2>
          <p className="text-sm text-black">
            Our team of experts is available around the clock to guide you via
            WhatsApp. Any queries regarding new IDs or complaints will be
            addressed and resolved within 15 to 30 minutes.
          </p>
          <button
            onClick={handleClaimNowClick}
            className="mt-10 py-3 px-8 bg-gradient-to-r from-orange-500 via-white to-green-500 hover:from-green-500 hover:via-white hover:to-orange-500 text-black  text-lg rounded-full shadow-lg transform hover:scale-105 transition-all duration-300"
          >
            Get Your ID Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
