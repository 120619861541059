import './App.css';
// import Homepage from './components/indra/Homepage';
// import Homepage from './components/ChocoTreats/Homepage';
// import Homepage from './components/Phantomvideos/hompage';
// import Landinpage from './metaads/Landinpage';
// import Landingpage from './components/Landingpage/Hompage';
import Homepage from './components/Lindingpage/Homepage';
function App() {
  return (
    <div className="App">
       <Homepage/>
    </div>
  );
}

export default App;
