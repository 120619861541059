import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Marquee from "react-fast-marquee";

const Hero = ({ handleClaimNowClick }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: "false",
    });
  }, []);
  return (
    <div>
      <div className="w-full h-[100vh] bg-gradient-to-r from-orange-900 via-white to-green-700 md:p-10">
        <div className="grid md:grid-flow-
        col md:grid-cols-2 gap-5 grid-flow-row">
          <div className=" pl-10 pt-20">
            <h1
              data-aos="fade-right"
              className="md:text-[5rem] text-[2rem] font-[monstrat] font-bold uppercase text-white  "
            >
              भारतीय फैंटेसी गेम्स का असली मज़ा!
              <br/>
              आज ही खेलें 
            </h1>
            <button
              className="bg-gradient-to-r from-orange-500 via-white text-black to-green-600  px-20 mt-5 py-3 uppercase rounded-xl"
              onClick={handleClaimNowClick}
            >
              Get your !d now
            </button>
          </div>
          <div className="w-full md:h-[90vh] h-[60vh]   ">
            <img
              data-aos="fade-left"
              className=" w-full h-full object-contain"
              src="https://assets.gqindia.com/photos/653f832d74d841b9c747ab05/16:9/w_2560%2Cc_limit/educational-qualifications-of-the-Indian-team-players.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <Marquee
        autoFill
        className="bg-green-500 text-white p-2 fixed top-0 z-[35]"
      >
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🚀 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🌟 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🔥 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🚀 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🌟 Get 10% Discount on every refill
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🔥 Get 10% Discount on every refill
        </span>
      </Marquee>
    </div>
  );
};

export default Hero;
